import React, { useState } from "react";
import { Button, Modal, Form, Dropdown } from "react-bootstrap";

import axios from "axios";

const YourModalComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [service, setService] = useState("");

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalOpen2 = () => {
    setShowModal2(true);
  };

  const handleModalClose2 = () => {
    setShowModal2(false);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("your-name", name);
    formData.append("your-email", email);
    formData.append("your-phone", phoneNumber);
    formData.append("your-service", service);

    try {
      const response = await axios.post(
        "https://gatsby.lt/starover/wp-json/contact-form-7/v1/contact-forms/1173/feedback",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Duomenys sėkmingai išsiųsti į WordPress kontaktų formą");
      setShowModal(false);
      setShowModal2(true);
    } catch (error) {
      console.error(
        "Klaida siunčiant duomenis į WordPress kontaktų formą",
        error
      );
    }

    setName("");
    setEmail("");
    setPhoneNumber("");
    setService("");
    setShowModal(false);
  };

  return (
    <>
      <Button onClick={handleModalOpen}>Panta núna</Button>
      <Modal centered show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Panta núna</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Sendu inn umsókn og við munum hafa samband fljótlega.</p>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Nafn:</Form.Label>
              <Form.Control
                required
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Netfang:</Form.Label>
              <Form.Control
                required
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Símanúmer:</Form.Label>
              <Form.Control
                required
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formService">
              <Form.Label>Veldu þjónustu:</Form.Label>
              <Form.Control
                required
                as="select"
                value={service}
                onChange={(e) => setService(e.target.value)}
              >
                <option value="">Veldu þjónustu</option>
                <option value="Stigar">Stigar</option>
                <option value="Gluggar">Gluggar</option>
                <option value="Þök">Þök</option>
                <option value="Framhliðar">Framhliðar</option>
              </Form.Control>
            </Form.Group>
            <div className="d-flex flex-column align-items-right mt-5">
              <Button variant="primary" type="submit">
                Senda
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal centered show={showModal2} onHide={handleModalClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Panta núna</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Pöntunareyðublaðið hefur verið sent. Við munum hafa samband fljótlega.
        </Modal.Body>
      </Modal>
    </>
  );
};

export default YourModalComponent;
