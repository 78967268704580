import React, { useEffect, useState } from "react";
import { Container, Row, Col, Carousel, Modal } from "react-bootstrap";

const App = () => {
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const postId = 1120;

  useEffect(() => {
    fetch(`https://gatsby.lt/starover/wp-json/wp/v2/posts/${postId}`)
      .then((response) => response.json())
      .then((data) => {
        const contentHtml = data.content.rendered;
        const title = data.title.rendered;
        setTitle(title);
        const imageUrls = extractImageUrls(contentHtml);
        setImages(imageUrls);
      })
      .catch((error) => {
        console.error("Klaida gaudant nuotraukas:", error);
      });
    const extractImageUrls = (html) => {
      const imageRegex = /<img.*?src=["'](.*?)["']/g;
      const matches = html.matchAll(imageRegex);
      const urls = Array.from(matches, (match) => match[1]);
      return urls;
    };
  }, []);

  const slideImages = images.reduce((result, item, index, array) => {
    if (index % 4 === 0) result.push(array.slice(index, index + 4));
    return result;
  }, []);

  const slideImagess = images.reduce((result, item, index, array) => {
    if (index % 2 === 0) result.push(array.slice(index, index + 2));
    return result;
  }, []);

  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    if (e) {
      setDirection(e.direction);
    }
  };

  return (
    <Container>
      <Row>
        <h2 className="p-3">{title}</h2>
        <Col className="d-none d-md-block pb-5">
          <Carousel
            activeIndex={index}
            direction={direction}
            onSelect={handleSelect}
            interval={3000}
            controls={true}
          >
            {slideImages.map((slide, index) => (
              <Carousel.Item key={index}>
                <Row>
                  {slide.map((image, index) => (
                    <Col key={index} xs={12} sm={6} md={3} lg={3}>
                      <div
                        onClick={() => openModal(image)}
                        style={{ borderRadius: 20 }}
                      >
                        <img
                          style={{ borderRadius: 20 }}
                          src={image}
                          alt={`Slide ${index}`}
                          className="img-fixed-size d-block w-100 h-100"
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
        <Col className="d-block d-md-none">
          <Carousel
            activeIndex={index}
            direction={direction}
            onSelect={handleSelect}
            controls={true}
            interval={2000}
          >
            {slideImagess.map((slide, index) => (
              <Carousel.Item key={index}>
                <Row>
                  {slide.map((image, index) => (
                    <Col key={index} xs={6} sm={6} md={4} lg={3}>
                      <div
                        onClick={() => openModal(image)}
                        style={{ borderRadius: 20 }}
                      >
                        <img
                          style={{ borderRadius: 20 }}
                          src={image}
                          alt={`Slide ${index}`}
                          className="img-fixed-size d-block w-100 h-100"
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <img src={selectedImage} alt="Selected Image" className="w-100" />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default App;
