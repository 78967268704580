import React, { useEffect, useState, useRef } from "react";
import { Navbar, Nav, NavDropdown, Button, Container } from "react-bootstrap";
import { FaPhone, FaFacebook } from "react-icons/fa";
import logo from "../Images/logo.svg";
import "./Menu.css";
import { Sling as Hamburger } from "hamburger-react";
import Booking from "../Components/Booking";
import { Link } from "react-router-dom";
import Content from "../Components/Content";
import Gallery from "../Components/Gallery";
import Contacts from "../Components/Contacts";
import Footer from "../Components/Footer";
import axios from "axios";
import Helmet from "./Helmet";

const NavbarWithHeader = ({
  hideContent,
  stigar,
  gluggar,
  thok,
  framhlidar,
  umokkur,
  tengilidir,
}) => {
  const [headerText, setHeaderText] = useState("");
  const [contentText, setContentText] = useState("");
  const [animatedText, setAnimatedText] = useState(false);
  const [currentText, setCurrentText] = useState("");

  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const topRef = useRef(null);

  const containerStyles = {
    maxWidth: "1200px",
    margin: "0 auto",
  };
  const [isOpen, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [navbarBackground, setNavbarBackground] = useState("transparent");
  const [scrollPosition, setScrollPosition] = useState(0);

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const [stigarr, setStigarr] = useState(null);
  const [gluggarr, setGluggarr] = useState(null);
  const [thokk, setThokk] = useState(null);
  const [framhlidarr, setFramhlidarr] = useState(null);
  const [umokkurr, setUmokkurr] = useState(null);

  useEffect(() => {
    fetch("https://gatsby.lt/starover/wp-json/wp/v2/pages/1106")
      .then((response) => response.json())
      .then((data) => {
        const title = data.title.rendered;
        const content = data.content.rendered;
        const contentWithoutPTags = content.replace(/<\/?p>/g, ""); // Pašalinami <p> ir </p> žymės
        setContentText(contentWithoutPTags);
        setHeaderText(title);
        setAnimatedText(true); // Paleiskime animaciją
        console.log(contentWithoutPTags);

        let index = 0;
        const interval = setInterval(() => {
          if (index <= title.length) {
            setCurrentText(highlightSecondWord(title.slice(0, index)));
            index++;
          } else {
            clearInterval(interval);
          }
        }, 100); // Keiskite intervalo trukmę pagal savo poreikius
      })
      .catch((error) => {
        console.error("Įvyko klaida:", error);
      });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const highlightSecondWord = (text) => {
    const words = text.split(" ");
    if (words.length >= 2) {
      words[1] = `<span class="highlighted">${words[1]}</span>`;
    }
    return words.join(" ");
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          "https://gatsby.lt/starover/wp-json/wp/v2/posts/1175"
        );
        setStigarr(response.data);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          "https://gatsby.lt/starover/wp-json/wp/v2/posts/1178"
        );
        setGluggarr(response.data);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          "https://gatsby.lt/starover/wp-json/wp/v2/posts/1181"
        );
        setThokk(response.data);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          "https://gatsby.lt/starover/wp-json/wp/v2/posts/1184"
        );
        setFramhlidarr(response.data);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          "https://gatsby.lt/starover/wp-json/wp/v2/posts/1187"
        );
        setUmokkurr(response.data);
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, []);

  if (!gluggarr) {
    return null;
  }

  if (!stigarr) {
    return null;
  }

  if (!thokk) {
    return null;
  }

  if (!framhlidarr) {
    return null;
  }

  if (!umokkurr) {
    return null;
  }

  return (
    <div>
      <div
        className="App"
        style={{
          backgroundColor: "#FAFAFA",
        }}
      >
        <Navbar
          bg={
            scrollPosition > 50
              ? "black"
              : "transparent" && isOpen
              ? "black"
              : "transparent"
          }
          expand="lg"
          onToggle={handleToggle}
          expanded={expanded}
          fixed="top"
          style={{
            transition: scrollPosition > 50 ? "0.5s" : "0.5s",
          }}
        >
          <Container style={containerStyles}>
            <Link style={{ textDecoration: "none" }} to="/heim">
              <Navbar.Brand
                style={{
                  transition: scrollPosition ? "0.5s" : "0.5s",
                }}
              >
                <img
                  src={logo}
                  style={{
                    transition: scrollPosition > 50 ? "0.5s" : "0.5s",
                  }}
                  width={scrollPosition > 50 ? 70 : 90}
                  alt="Logo"
                  className="navbar-logo"
                />
                <span style={{ color: "#fff", fontSize: 30 }}>
                  star<b>over.is</b>
                </span>
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <Hamburger
                toggled={isOpen}
                toggle={setOpen}
                size={24}
                color="#fff"
              />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Link style={{ textDecoration: "none" }} to="/heim">
                  <Nav.Link
                    href="/heim"
                    onClick={() =>
                      setOpen(false) & setExpanded(false) & scrollToTop()
                    }
                  >
                    Heim
                  </Nav.Link>
                </Link>
                <NavDropdown
                  title="Verkefni"
                  id="verkefni-dropdown"
                  onClick={() =>
                    setDropdownOpen(true) && setDropdownOpen(false)
                  }
                  onMouseEnter={() => setDropdownOpen(true)}
                  onMouseLeave={() => setDropdownOpen(false)}
                  style={{
                    transition: dropdownOpen ? "opacity 0.5s" : "opacity 0.5s",
                  }}
                  className="custom-dropdown"
                  show={dropdownOpen}
                >
                  <Link style={{ textDecoration: "none" }} to="/stigar">
                    <NavDropdown.Item
                      onClick={() =>
                        setOpen(false) & setExpanded(false) & scrollToTop()
                      }
                      href="#stigar"
                    >
                      Stigar
                    </NavDropdown.Item>
                  </Link>
                  <Link style={{ textDecoration: "none" }} to="/gluggar">
                    <NavDropdown.Item
                      onClick={() =>
                        setOpen(false) & setExpanded(false) & scrollToTop()
                      }
                      href="#gluggar"
                    >
                      Gluggar
                    </NavDropdown.Item>
                  </Link>
                  <Link style={{ textDecoration: "none" }} to="/thok">
                    <NavDropdown.Item
                      onClick={() =>
                        setOpen(false) &
                        setExpanded(false) &
                        window.scrollTo(0, 0)
                      }
                      href="#thok"
                    >
                      Þök
                    </NavDropdown.Item>
                  </Link>
                  <Link style={{ textDecoration: "none" }} to="/framhlidar">
                    <NavDropdown.Item
                      onClick={() =>
                        setOpen(false) & setExpanded(false) & scrollToTop()
                      }
                      href="#framhlidar"
                    >
                      Framhliðar
                    </NavDropdown.Item>
                  </Link>
                </NavDropdown>
                <Link style={{ textDecoration: "none" }} to="/um-okkur">
                  <Nav.Link
                    href="#um-okkur"
                    onClick={() =>
                      setOpen(false) & setExpanded(false) & scrollToTop()
                    }
                  >
                    Um okkur
                  </Nav.Link>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/tengilidir">
                  <Nav.Link
                    href="#tengilidir"
                    onClick={() =>
                      setOpen(false) & setExpanded(false) & scrollToTop()
                    }
                  >
                    Tengiliðir
                  </Nav.Link>
                </Link>
              </Nav>
              <Nav className="ml-auto" style={{ marginLeft: "auto" }}>
                <a
                  style={{
                    textDecoration: "none",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                  href="https://www.facebook.com/starover.is"
                  target="_blank"
                >
                  <div className="facebook">
                    <FaFacebook
                      size={25}
                      style={{ marginTop: 6, marginRight: 10 }}
                    />
                  </div>
                </a>
                <Button variant="primary">
                  {" "}
                  <a
                    style={{
                      textDecoration: "none",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      color: "#fff",
                    }}
                    href="tel:+3548886963"
                    target="_blank"
                  >
                    <FaPhone style={{ marginRight: "0.5rem" }} />
                    888 6963
                  </a>
                </Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Helmet title="Starover.is | Heim" />
        <header
          ref={topRef}
          style={{
            position: "relative",
            height: hideContent ? "90vh" : "75vh",
            transition: hideContent ? "2s" : "2s",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              backgroundImage:
                "url(https://gatsby.lt/starover/wp-content/uploads/2023/05/Image-4.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Container>
              <div
                style={{ textAlign: "center", color: "#fff", marginTop: 60 }}
              >
                {hideContent ? (
                  <h1
                    style={{ transition: hideContent ? "2s" : "2s" }}
                    dangerouslySetInnerHTML={{ __html: currentText }}
                  />
                ) : null}
                {hideContent ? (
                  <span style={{ transition: hideContent ? "2s" : "2s" }}>
                    {contentText}
                  </span>
                ) : null}
              </div>
              {stigar ? (
                <>
                  <Helmet title="Starover.is | Stigar" />
                  <Container style={{ transition: "0.6s", color: "#d53636" }}>
                    <h1>{stigarr.title.rendered}</h1>
                    <div
                      style={{
                        color: "#fff",
                        textShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: stigarr.content.rendered,
                      }}
                    ></div>
                  </Container>
                </>
              ) : null}
              {gluggar ? (
                <Container style={{ transition: "0.6s" }}>
                  <Helmet title="Starover.is | Gluggar" />
                  <h1 style={{ color: "#d53636" }}>
                    {gluggarr.title.rendered}
                  </h1>
                  <div
                    style={{ color: "#fff" }}
                    dangerouslySetInnerHTML={{
                      __html: gluggarr.content.rendered,
                    }}
                  ></div>
                </Container>
              ) : null}
              {thok ? (
                <Container style={{ transition: "0.6s" }}>
                  <Helmet title="Starover.is | Þök" />
                  <h1 style={{ color: "#d53636" }}>{thokk.title.rendered}</h1>
                  <div
                    style={{ color: "#fff" }}
                    dangerouslySetInnerHTML={{
                      __html: thokk.content.rendered,
                    }}
                  ></div>
                </Container>
              ) : null}
              {framhlidar ? (
                <Container style={{ transition: "0.6s" }}>
                  <Helmet title="Starover.is | Framhliðar" />
                  <h1 style={{ color: "#d53636" }}>
                    {framhlidarr.title.rendered}
                  </h1>
                  <div
                    style={{ color: "#fff" }}
                    dangerouslySetInnerHTML={{
                      __html: framhlidarr.content.rendered,
                    }}
                  ></div>
                </Container>
              ) : null}
              {umokkur ? (
                <Container style={{ transition: "0.6s" }}>
                  <Helmet title="Starover.is | Um okkur" />
                  <h1 style={{ color: "#d53636" }}>
                    {umokkurr.title.rendered}
                  </h1>
                  <div
                    style={{ color: "#fff" }}
                    dangerouslySetInnerHTML={{
                      __html: umokkurr.content.rendered,
                    }}
                  ></div>
                </Container>
              ) : null}
              {tengilidir ? (
                <Container style={{ transition: "0.6s" }}>
                  <Helmet title="Starover.is | Tengiliðir" />
                  <Contacts hide="none" content="center" />
                </Container>
              ) : null}
              {hideContent ? (
                <div style={{ marginTop: 20 }}>
                  <Booking />
                </div>
              ) : null}
            </Container>
          </div>
        </header>
        <Content />
        <Gallery />
        <Contacts />
        <Footer />
      </div>
    </div>
  );
};

export default NavbarWithHeader;
