import React from "react";
import { Helmet } from "react-helmet";

const SEO = (props) => {
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <meta
          name="description"
          content="Við erum fyrirtæki sem framleiðir og setur upp viðarstiga, glugga, framhlið og þök. Við höfum safnað töluverðri reynslu og þekkjum okkar starf mjög vel."
        />
        <meta name="keywords" content="Stigar, gluggar, Þök, framhliðar" />
      </Helmet>
    </>
  );
};

export default SEO;
