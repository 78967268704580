import React, { useEffect, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { Container, Row, Col } from "react-bootstrap";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const PostTitle = (props) => {
  const [titles, setTitles] = useState({
    title: "",
    anotherTitle: "",
  });

  const [contactInfo, setContactInfo] = useState({
    email: "",
    phoneNumber: "",
    address: "",
  });

  const fetchContactInfo = async (postId) => {
    try {
      const response = await fetch(
        `https://gatsby.lt/starover/wp-json/wp/v2/posts/${postId}`
      );
      const data = await response.json();
      const content = data.content.rendered;

      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");
      const paragraphs = doc.getElementsByTagName("p");

      if (paragraphs.length >= 3) {
        const email = paragraphs[0].textContent;
        const phoneNumber = paragraphs[1].textContent;
        const address = paragraphs[2].textContent;
        setContactInfo({ email, phoneNumber, address });
      }
    } catch (error) {
      console.error(
        `Klaida gaunant kontakto informaciją su ID ${postId}:`,
        error
      );
    }
  };

  useEffect(() => {
    const fetchPostTitle = async (postId, key) => {
      try {
        const response = await fetch(
          `https://gatsby.lt/starover/wp-json/wp/v2/posts/${postId}`
        );
        const data = await response.json();
        const title = data.title.rendered;
        setTitles((prevTitles) => ({ ...prevTitles, [key]: title }));
      } catch (error) {
        console.error(
          `Klaida gaunant posto pavadinimą su ID ${postId}:`,
          error
        );
      }
    };

    fetchPostTitle(1166, "title");
    fetchPostTitle(1169, "anotherTitle");
    fetchContactInfo(1166);
  }, []);

  const MapComponent = withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: 64.0483203, lng: -22.0038789 }}
    >
      <Marker position={{ lat: 64.0483203, lng: -22.0038789 }} />
    </GoogleMap>
  ));

  return (
    <div>
      <Container>
        <Row style={{ justifyContent: props.content }}>
          <Col sm={12} lg={6}>
            <h2 style={{ display: props.hide }} className="p-3">
              {titles.title}
            </h2>
            <div
              className="contacts"
              style={{
                backgroundColor: "#fff",
                margin: 10,
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
                borderRadius: 10,
                fontSize: 20,
                display: "flex",
                alignItems: "center",
                padding: 20,
              }}
            >
              <AiOutlineMail
                size={22}
                color="#d53636"
                style={{ marginRight: 10 }}
              />
              <a
                style={{
                  textDecoration: "none",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: "#000",
                }}
                href="mailto:info@starover.is"
                target="_blank"
              >
                <span>{contactInfo.email}</span>
              </a>
            </div>
            <div
              className="contacts"
              style={{
                backgroundColor: "#fff",
                margin: 10,
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
                borderRadius: 10,
                fontSize: 20,
                display: "flex",
                alignItems: "center",
                padding: 20,
              }}
            >
              <FiPhoneCall
                size={22}
                color="#d53636"
                style={{ marginRight: 10 }}
              />
              <a
                style={{
                  textDecoration: "none",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: "#000",
                }}
                href="tel:+3548886963"
                target="_blank"
              >
                <span>{contactInfo.phoneNumber}</span>
              </a>
            </div>
            <div
              className="contacts"
              style={{
                backgroundColor: "#fff",
                margin: 10,
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
                borderRadius: 10,
                fontSize: 20,
                display: "flex",
                alignItems: "center",
                padding: 20,
              }}
            >
              <GoLocation
                size={22}
                color="#d53636"
                style={{ marginRight: 10 }}
              />
              <a
                style={{
                  textDecoration: "none",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: "#000",
                }}
                href="https://www.google.is/maps/place/Ceramic+Pro+Iceland/@64.048318,-22.1537393,12z/data=!4m10!1m2!2m1!1zUmF1w7BoZWxsYSAxLCBSZXlramF2w61rLCBJY2VsYW5k!3m6!1s0x48d60dcb4114c027:0xbd775419fd11c165!8m2!3d64.048318!4d-22.001304!15sCiFSYXXDsGhlbGxhIDEsIFJleWtqYXbDrWssIEljZWxhbmSSARduYW5vdGVjaG5vbG9neV9lbmdpbmVlcuABAA!16s%2Fg%2F11hbvbywg0"
                target="_blank"
              >
                <span>{contactInfo.address}</span>
              </a>
            </div>
          </Col>
          <Col style={{ display: props.hide }}>
            <h2 className="p-3">{titles.anotherTitle}</h2>
            <div style={{ height: "220px", width: "100%", zIndex: 9 }}>
              <MapComponent
                containerElement={<div style={{ height: "100%" }} />}
                mapElement={<div style={{ height: "100%" }} />}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PostTitle;
