import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

import Roof from "../Images/roof.svg";
import Window from "../Images/window.svg";
import Stairs from "../Images/stairs.svg";
import Foundations from "../Images/foundations.svg";

const PostContent = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          "https://gatsby.lt/starover/wp-json/wp/v2/pages/1114"
        );
        setPost(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError("Įvyko klaida gaudant įrašo informaciją.");
        setLoading(false);
      }
    };

    fetchPost();
  }, []);

  const getParagraphsAndHeadings = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const elements = doc.querySelectorAll("h2, h3, h4, h5, h6, p");
    const paragraphs = [];

    let currentHeading = null;

    elements.forEach((element) => {
      const tagName = element.tagName.toLowerCase();
      const text = element.textContent.trim();

      if (
        tagName === "h2" ||
        tagName === "h3" ||
        tagName === "h4" ||
        tagName === "h5" ||
        tagName === "h6"
      ) {
        currentHeading = {
          tagName,
          text,
          paragraphs: [],
        };
        paragraphs.push(currentHeading);
      } else if (tagName === "p") {
        const paragraph = {
          tagName: "p",
          text,
        };
        if (currentHeading) {
          currentHeading.paragraphs.push(paragraph);
        } else {
          paragraphs.push(paragraph);
        }
      }
    });

    return paragraphs;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!post) {
    return null;
  }

  const images = [Stairs, Window, Foundations, Roof];

  const paragraphsAndHeadings = getParagraphsAndHeadings(
    post.content.rendered
  ).map((element, index) => ({
    ...element,
    images: images.slice(index, index + 1),
  }));

  return (
    <>
      <h2 style={{ fontSize: 30 }}>{post.title.rendered}</h2>
      <Container>
        <Row>
          {paragraphsAndHeadings.map((element, index) => (
            <React.Fragment key={index}>
              {element.tagName === "p" ? (
                <Col>{element.text}</Col>
              ) : (
                <Col lg={3} md={6} sm={12}>
                  <div
                    style={{
                      backgroundColor: "#fff",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.10)",
                      padding: 10,
                      margin: 10,
                      borderRadius: 10,
                    }}
                  >
                    {element.images.map((image, imageIndex) => (
                      <img
                        style={{ margin: 20 }}
                        key={imageIndex}
                        width={110}
                        height={110}
                        src={image}
                        alt="Ikona"
                      />
                    ))}
                    <h2 style={{ fontSize: 24 }}>{element.text}</h2>
                    {element.paragraphs.map((paragraph, paragraphIndex) => (
                      <p key={paragraphIndex}>{paragraph.text}</p>
                    ))}
                  </div>
                </Col>
              )}
            </React.Fragment>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default PostContent;
