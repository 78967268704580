import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { BsFacebook } from "react-icons/bs";

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <footer
        style={{
          background: "linear-gradient(to bottom, #000 20%, #5A5A5A)",
          borderTop: "4px solid #D53636",
          paddingTop: 20,
          paddingBottom: 20,
          marginTop: 20,
        }}
      >
        <Container>
          <Row>
            <Col lg={4} sm={12}>
              <h5
                style={{
                  textAlign: "left",
                  fontFamily: "Montserrat-Bold",
                  color: "#fff",
                }}
              >
                UM STAROVER.IS
              </h5>
              <p
                style={{
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: 800,
                  color: "#fff",
                }}
              >
                Félagið var stofnað í janúar 2021. Meginsvið félagsins er
                viðhald og byggingaframkvæmdir. Sem eigandi fyrirtækisins hef ég
                starfað í yfir 20 ár á þessu sviði og vantaði þekkingu og færni.
              </p>
            </Col>
            <Col lg={4} sm={12}>
              <h5
                style={{
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: 800,
                  color: "#fff",
                }}
              >
                STAÐSETNING
              </h5>
              <p
                style={{
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: 800,
                  color: "#fff",
                }}
              >
                Rauðhella 1, Reykjavík, Iceland
              </p>
            </Col>
            <Col lg={4} sm={12}>
              <h5
                style={{
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: 800,
                  color: "#fff",
                  textTransform: "uppercase",
                }}
              >
                Þjónusta
              </h5>
              <div>
                <Link
                  style={{
                    textDecoration: "none",
                    marginTop: 0,
                    paddingTop: 0,
                    textAlign: "left",
                  }}
                  to="/stigar"
                >
                  <div
                    style={{
                      margin: 0,
                      padding: 0,
                      color: "#fff",
                      fontFamily: "Montserrat",
                      fontWeight: 800,
                    }}
                  >
                    Stigar
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    marginTop: 0,
                    paddingTop: 0,
                    textAlign: "left",
                  }}
                  to="/gluggar"
                >
                  <div
                    style={{
                      margin: 0,
                      padding: 0,
                      color: "#fff",
                      fontFamily: "Montserrat",
                      fontWeight: 800,
                    }}
                  >
                    Gluggar
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    marginTop: 0,
                    paddingTop: 0,
                    textAlign: "left",
                  }}
                  to="/thok"
                >
                  <div
                    style={{
                      margin: 0,
                      padding: 0,
                      color: "#fff",
                      fontFamily: "Montserrat",
                      fontWeight: 800,
                    }}
                  >
                    Þök
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    marginTop: 0,
                    paddingTop: 0,
                    textAlign: "left",
                  }}
                  to="/framhlidar"
                >
                  <div
                    style={{
                      margin: 0,
                      padding: 0,
                      color: "#fff",
                      fontFamily: "Montserrat",
                      fontWeight: 800,
                    }}
                  >
                    Framhliðar
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <div style={{ backgroundColor: "#000", paddingTop: 10 }}>
        <Container>
          <Row>
            <Col>
              <div style={{ textAlign: "left" }}>
                <p
                  style={{
                    textAlign: "left",
                    fontFamily: "Montserrat",
                    fontWeight: 800,
                    color: "#fff",
                  }}
                >
                  Höfundarréttur © 2021 <b>Starover Ehf</b>. Allur réttur
                  áskilinn.
                </p>
              </div>
            </Col>
            <Col lg={12}>
              <div style={{ textAlign: "right" }}>
                <p style={{ color: "#fff" }}>
                  <a
                    style={{
                      textDecoration: "none",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      color: "#fff",
                    }}
                    href="https://www.facebook.com/starover.is"
                    target="_blank"
                  >
                    <BsFacebook size={20} color="#fff" />
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
