import logo from "./logo.svg";
import Menu from "./Components/Menu";
import "./App.css";
import "./Components/Global.css";
import Content from "./Components/Content";
import Gallery from "./Components/Gallery";
import Contacts from "./Components/Contacts";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Menu hideContent={true} />} />
        <Route path="/heim" element={<Menu hideContent={true} />} />
        <Route path="/stigar" element={<Menu stigar={true} />} />
        <Route path="/gluggar" element={<Menu gluggar={true} />} />
        <Route path="/thok" element={<Menu thok={true} />} />
        <Route path="/framhlidar" element={<Menu framhlidar={true} />} />
        <Route path="/um-okkur" element={<Menu umokkur={true} />} />
        <Route path="/tengilidir" element={<Menu tengilidir={true} />} />
      </Routes>
    </Router>
  );
}

export default App;
